/* Container for the About Us section */
.about-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    position: relative;
    background-color: transparent; /* Assuming a black background for better contrast */
    color: white;  /* White text color */
    overflow: contain;
    height: 100vh; /* Use vh for full height */
    z-index: 100;
}

/* Image container */
.about-images {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%; /* Increased size for better visibility */
    height: 15rem;
    background-color: transparent;
}

/* Each image will be absolutely positioned with overlap */
.about-images img {
    width: 50%; /* Adjust image width */
    height: auto;
    position: absolute;
    transition: transform 0.5s ease-in-out; /* Default transition */
    background-color: transparent;
}

/* Positioning each image differently for overlap effect */
.about-images img:nth-child(1) {
    top: -10;
    left: 0;
    opacity: 0.9; /* Slightly transparent */
    z-index: 2;
}

.about-images img:nth-child(2) {
    top: 10%;
    left: 40%;
    opacity: 0.85; /* Less transparent */
}

.about-images img:nth-child(3) {
    top: 50%;
    left: 50%;
    opacity: 0.8; /* More transparent */
}

.about-images img:nth-child(4) {
    top: 60%;
    left: 35%;
    opacity: 1; /* Fully opaque */
    z-index: 4;
}

/* Text content */
.about-text {
    width: 40%;
    padding-left: 20px;
}

.about-text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;  /* White text */
}

.about-text p {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 30px;
    color: white;  /* White text */
}

/* Learn More Button with hover animation */
.learn-more-btn {
    padding: 10px 30px;
    background-color: transparent;
    color: white;
    border: 2px solid white; /* Add a white border */
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease, border-color 0.3s ease; /* Transition for border color */
}

.learn-more-btn:hover {
    color: white; /* Change text color on hover */
    border-color: red; /* Change border color on hover */
}

.learn-more-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: red; /* Background color of the hover effect */
    z-index: 0; /* Set z-index lower than the text */
    transition: width 0.4s ease;
}

.learn-more-btn:hover::before {
    width: 100%; /* Expand background on hover */
}

.learn-more-btn span {
    position: relative;
    z-index: 1; /* Set z-index higher than the before pseudo-element */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .about-us {
        flex-direction: column; /* Stack elements vertically */
        padding-bottom: 10px; /* Adjust padding */
        margin-bottom: 10%;
        height: fit-content; /* Allow height to adjust */
        width: 100%;
    }

    .about-images {
        width: 100%; /* Full width for better visibility */
        margin-top: 10px; 
        position: relative; /* Keep images in relative position */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Fourth image centered */
    .about-images img:nth-child(4) {
        position: relative;
        top: 30%;
        left: 0;
        z-index: 5; /* Bring it above other images */
        opacity: 1; /* Full opacity */
        width: 60%; /* Make it slightly larger */
    }

    /* Adjust the positioning of the other images around the center image */
    .about-images img:nth-child(1),
    .about-images img:nth-child(2),
    .about-images img:nth-child(3) {
        position: absolute;
        width: 40%; /* Smaller than the central image */
       
    }

    /* First image on the top-left */
    .about-images img:nth-child(1) {
        top: 20%;
        left: 5%;
        z-index: 2;
        opacity: 0.7;
    }

    /* Second image on the right */
    .about-images img:nth-child(2) {
        top: 50%;
        right: 0%;
        z-index: 1;
        opacity: 0.5;
        
    }

    /* Third image on the bottom-left */
    .about-images img:nth-child(3) {
        bottom: -40%;
        left: 50%;
        z-index: 3;
        opacity: 0.6;
       
    }

    .about-text {
        width: 100%; /* Full width for text */
        padding: 0; /* Remove padding */
        text-align: center; /* Center align text */
    }

    .about-text h2 {
        font-size: 2.2rem; /* Slightly larger for better readability */
         /* Space below heading */
    }

    .about-text p {
        font-size: 1rem; /* Maintain paragraph size */
        line-height: 1.5; /* Increase line height for better readability */
    }

    .learn-more-btn {
        width: 80%; /* Full width for button */
        
        padding: 5px; /* Increase padding for touch targets */
    }
}

@media (max-width: 480px) {
    .about-text h2 {
        font-size: 1.75rem; /* Adjust heading size for smaller screens */
    }

    .about-text p {
        font-size: 0.9rem; /* Keep paragraph size manageable */
    }

    .learn-more-btn {
        padding: 10px 20px; /* Adjust button padding */
    }
}
